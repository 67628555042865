import React from 'react';
import styled from 'styled-components/macro';

import { data } from './const/DistancePricingData';
import TitleWithIcon from '../../components/TitleWithIcon';
import DistanceIcon from '../../assets/img/Distance.png';
import DistancePricing from '../shared/DistancePricing';

import { ArrowRightAlt } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import colors from '../../assets/colors';

const Container = styled.div`
  max-width: 948px;
  margin: 0 auto;
`;
const StyledButton = styled(Button)`
  margin: auto;
  width: 100%;
  &.button {
    color: ${colors.primary};
  }
`;
const ArrowRight = styled(ArrowRightAlt)`
  color: ${colors.primary} !important;
  margin-left: 1rem;
  font-size: 30px !important;
`;

const HomePricingDistance = () => {
  return (
    <Container>
      <TitleWithIcon
        title="Distance"
        icon={DistanceIcon}
        description={
          <div>
            Starting from only <b>RM5</b>, dispatch rates are calculated based
            on distance and the vehicle you need.
          </div>
        }
      />
      <DistancePricing data={data} />
      <br />
      <StyledButton
        className="button"
        href="https://gogetmy.zendesk.com/hc/en-us/articles/900001763763-Learn-about-GoGet-s-prices"
      >
        LEARN MORE ABOUT PRICING
        <ArrowRight />
      </StyledButton>
    </Container>
  );
};

export default HomePricingDistance;
