import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import styled from 'styled-components/macro';

const appStore = './assets/app-store.png';
const googlePlay = './assets/google-play.png';
const mobileScreenShot = './assets/mobile-screen-shot.png';

const Container = styled.div`
  padding-bottom: ${props => (props.noPaddingBottom ? '0' : '7rem')};
  padding-top: 5rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: bottom;
  justify-content: center;
  @media (max-width: 950px) {
    font-size: 18px;
    line-height: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;

  @media (max-width: 950px) {
    text-align: center;
    width: 62%;
  }
  @media (max-width: 500px) {
    text-align: center;
    width: unset;
  }
`;
const ImageApp = styled.div`
  width: 258px;
  margin-right: 15px;
  @media (max-width: 950px) {
    margin-right: unset;
  }
  @media (max-width: 700px) {
    margin: auto;
  }
`;
const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: end;
    height: 200px;
  }
`;
const ImageContainer = styled.div`
  width: 300px;
  margin-right: 3rem;
  @media (max-width: 950px) {
    width: 270px;
    margin-right: 0;
  }
`;
const InnerContainer = styled.div`
  @media (max-width: 950px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;
const Link = styled.a`
  @media (max-width: 900px) {
    display: flex;
    margin-bottom: 1rem;
  }
`;

const DownloadUs = ({
  image,
  text,
  appleLink,
  googleLink,
  noPaddingBottom,
  appDownloadType,
  pageSectionAnalytics
}) => {
  return (
    <Container noPaddingBottom={noPaddingBottom}>
      <ImageContainer>
        {image || (
          <StaticImage
            src={mobileScreenShot}
            placeholder="blurred"
            alt="GoGet App"
          />
        )}
      </ImageContainer>
      <InnerContainer>
        <Title>{text || 'Enjoy convenience at  your fingertips.'}</Title>
        <ImagesWrapper>
          <Link
            href={
              googleLink ||
              'https://play.google.com/store/apps/details?id=com.goget&hl=en'
            }
            onClick={() => {
              window.analytics.track(appDownloadType, {
                category: 'android',
                label: { pageSectionAnalytics }
              });
            }}
          >
            <ImageApp>
              <StaticImage
                src={googlePlay}
                placeholder="blurred"
                alt="Google Play Store"
              />
            </ImageApp>
          </Link>
          <Link
            href={
              appleLink ||
              'https://apps.apple.com/us/app/goget-hire-on-demand/id1033297708?ls=1'
            }
            onClick={() => {
              window.analytics.track(appDownloadType, {
                category: 'ios',
                label: { pageSectionAnalytics }
              });
            }}
          >
            <ImageApp>
              <StaticImage
                src={appStore}
                placeholder="blurred"
                alt="Google Play Store"
              />
            </ImageApp>
          </Link>
        </ImagesWrapper>
      </InnerContainer>
    </Container>
  );
};

export default DownloadUs;
