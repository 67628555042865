import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 1rem;
`;
const StyledAvatar = styled.div`
  height: 80px;
  width: 80px;
  @media (max-width: 740px) {
    height: 72px;
    width: 72px;
  }
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 19.5px;
  line-height: 27px;
  margin: 0;
  @media (max-width: 740px) {
    font-size: 16px;
  }
`;
const Description = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  @media (max-width: 740px) {
    font-size: 14px;
  }
`;
const Disclaimer = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  margin: 0;
  @media (max-width: 740px) {
    font-size: 12px;
  }
`;

const FlexAvatarTitleDescription = props => {
  const { image, title, description, disclaimer } = props;
  return (
    <Container>
      <StyledAvatar>{image}</StyledAvatar>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
      </TitleContainer>
    </Container>
  );
};

export default FlexAvatarTitleDescription;
