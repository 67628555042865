import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Distance = '../../../assets/img/Distance.png';
const Typeofjob = '../../../assets/img/Typeofjob.png';

const data = [
  {
    id: 1,
    icon: (
      <StaticImage
        src={Typeofjob}
        placeholder="blurred"
        loading="eager"
        alt="Type of job"
      />
    ),
    buttonText: 'Type of job'
  },
  {
    id: 2,
    icon: (
      <StaticImage
        src={Distance}
        placeholder="blurred"
        loading="eager"
        alt="Distance"
      />
    ),
    buttonText: 'Distance'
  }
];

export { data };
