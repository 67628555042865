import React from 'react';
import Bike from '../../../assets/img/bike.png';
import Car from '../../../assets/img/car.png';
import SUV from '../../../assets/img/SUV.png';

export const data = [
  {
    type: 'Bike',
    img: Bike,
    pricing_categories: [
      {
        name: 'Distance',
        pick_up: 'Time slot',
        values: ['0 - 5km', '5.01 - 7km', '7.01 - 10km', 'More than 10km']
      },
      {
        name: 'Same-day',
        discount: '15% discount on Standard',
        pick_up: 'Pick up before 3pm',
        values: [
          'RM5',
          'RM6 - RM7',
          'RM8 - RM9',
          <div>
            15% discount from <br /> standard{' '}
          </div>
        ]
      },
      {
        name: 'Standard',
        discount: 'Flexible Timing',
        pick_up: '24 hours, 7 days a week',
        values: [
          'RM6',
          'RM7 - RM8',
          'RM9 - RM10',
          <div>
            {' '}
            RM1 - RM1.05/ per <br />
            additional km{' '}
          </div>
        ]
      },
      {
        name: 'Express',
        discount: '100% Guaranteed',
        pick_up: 'Delivery between 8am to 9pm',
        values: [
          'RM7',
          'RM8 - RM9',
          'RM10 - RM11',
          <div>
            RM1 - RM1.1/ per <br />
            additional km
          </div>
        ]
      }
    ]
  },
  {
    type: 'Car',
    img: Car,
    pricing_categories: [
      {
        name: 'Distance',
        pick_up: 'Time slot',
        values: ['0 - 5km', '5.01 - 7km', '7.01 - 10km', 'More than 10km']
      },
      {
        name: 'Same-day',
        discount: '15% discount on Standard',
        pick_up: 'Pick up before 3pm',
        values: [
          'RM7',
          'RM8 - RM9',
          'RM9 - RM10',
          <div>
            15% discount from <br /> standard{' '}
          </div>
        ]
      },
      {
        name: 'Standard',
        discount: 'Flexible Timing',
        pick_up: '24 hours, 7 days a week',
        values: [
          'RM8',
          'RM9 - RM10',
          'RM10 - RM12',
          <div>
            {' '}
            RM1 - RM1.25/ per <br />
            additional km{' '}
          </div>
        ]
      },
      {
        name: 'Express',
        discount: '100% Guaranteed',
        pick_up: 'Delivery between 8am to 9pm',
        values: [
          'RM9',
          'RM10 - RM11',
          'RM11 - RM13',
          <div>
            RM1.1 - RM1.3/ per <br />
            additional km
          </div>
        ]
      }
    ]
  },
  {
    type: 'SUV',
    img: SUV,
    pricing_categories: [
      {
        name: 'Distance',
        pick_up: 'Time slot',
        values: ['0 - 5km', '5.01 - 7km', '7.01 - 10km', 'More than 10km']
      },
      {
        name: 'Same-day',
        disabled: true
      },
      {
        name: 'Standard',
        discount: 'Flexible Timing',
        pick_up: '24 hours, 7 days a week',
        values: [
          'RM15',
          'RM16 - RM17',
          'RM18 - RM20',
          <div>
            {' '}
            RM2.0 - RM2.4/ per <br />
            additional km{' '}
          </div>
        ]
      },
      {
        name: 'Express',
        disabled: true
      }
    ]
  }
];
