import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
// components
import Layout from '../../components/Layout';
import HomeOurPricing from '../../views/HomePricingPage/HomeOurPricing';
import HomePricingDistance from '../../views/HomePricingPage/Distance';
import HomePricingTypeofJob from '../../views/HomePricingPage/HomePricingTypeofJob';
import TopHeader from '../../views/shared/TopHeaderV2';
import DownloadUs from '../../views/shared/DownloadUs';
import PaymentOptions from '../../views/HomePricingPage/PaymentOptions';
import FAQSection from '../../views/shared/FAQ';
import HeaderSeo from '../../components/HeaderSeo';
// data
import { FaqData } from '../../views/HomePricingPage/const/HomePricingFAQdata';
// images
import DesktopBackgroundFAQ from '../../assets/img/homeAndLifePricing/DesktopBackgroundFAQ.png';
import DesktopJobDistance from '../../assets/img/homeAndLifePricing/DesktopJobDistance.png';
import MobileFAQ from '../../assets/img/homeAndLifePricing/MobileFAQ.png';
import MobileJobDistance from '../../assets/img/homeAndLifePricing/MobileJobDistance.png';

import MetaImg from '../../assets/img/meta/homelife_pricing.png';

import { SharedBackground } from '../../assets/sharedStyle';
import { graphql } from 'gatsby';

const HelpAndLifePricingBanner =
  '../../assets/img/homeAndLifePricing/Banner-Desktop.jpg';
const MobileHelpAndLifePricingBanner =
  '../../assets/img/homeAndLifePricing/Banner-Mobile.jpg';

const WrapperTop = styled.div`
  background-image: url(${DesktopJobDistance});
  ${SharedBackground};
  padding-bottom: 5rem;
  @media (max-width: 738px) {
    background-image: url(${MobileJobDistance});
    padding-bottom: 9rem;
  }
`;

const WrapperBottom = styled.div`
  background-image: url(${DesktopBackgroundFAQ});
  margin-top: 5rem;
  ${SharedBackground};
  @media (max-width: 550px) {
    margin-top: 0;
    background-image: url(${MobileFAQ});
  }
`;

const HomePricing = props => {
  const {
    data: { datoCmsHomeLifePricing }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const homeConstant = {
    title: (
      <div>
        {' '}
        Extra help at <br /> affordable rates
      </div>
    ),
    subTitle:
      'Get trusted and reliable people to help complete your tasks within your budget.'
  };
  const refTypeOfJob = useRef(null);
  const refDistance = useRef(null);

  const handleClick = clickType => {
    const behaviour = {
      behavior: 'smooth',
      block: 'start'
    };
    if (clickType === 'typeOfJob') {
      if (refTypeOfJob.current) {
        refTypeOfJob.current.scrollIntoView(behaviour);
      }
    } else if (clickType === 'distance') {
      if (refDistance.current) {
        refDistance.current.scrollIntoView(behaviour);
      }
    }
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Affordable and Reliable Part Timers for your Home and Daily Life"
        description="Stay within your budget and hire reliable part timers to help complete tasks in your daily life."
        image={MetaImg}
        keywords="personal assistant rate, hourly rates, part time salary, part time hours"
        pathname={props.location.pathname}
        schemaMarkup={datoCmsHomeLifePricing.schemaMarkup}
      />
      <Layout hideDownloadGoGetter>
        <TopHeader
          backgroundImg={
            <StaticImage
              src={HelpAndLifePricingBanner}
              loading="eager"
              style={{ height: '100%' }}
              objectPosition="left top"
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={MobileHelpAndLifePricingBanner}
              loading="eager"
              style={{ height: '100%' }}
              imgStyle={{ height: '100%' }}
              objectFit="cover"
              quality={100}
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          title={homeConstant.title}
          description={homeConstant.subTitle}
          h4="Home & Life Pricing"
        />

        <WrapperTop>
          <HomeOurPricing onClick={handleClick} />
          <span ref={refTypeOfJob} style={{ scrollMargin: '60px' }}>
            <HomePricingTypeofJob />
          </span>
          <div
            ref={refDistance}
            style={{ marginTop: '3rem', scrollMargin: '60px' }}
          >
            <HomePricingDistance />
          </div>
        </WrapperTop>

        <PaymentOptions />
        <WrapperBottom>
          <FAQSection data={FaqData} />
          <DownloadUs
            appDownloadType="customer_app_download"
            pageSectionAnalytics="body"
          />
        </WrapperBottom>
      </Layout>
    </>
  );
};

export default HomePricing;

export const query = graphql`
  query HomeLifePricingQuery {
    datoCmsHomeLifePricing {
      schemaMarkup
    }
  }
`;
