import React from 'react';
import wallet from './assets/wallet.png';

export const FaqData = [
  {
    question: 'How do I pay for my jobs?',
    content: (
      <>
        <br />
        <div>i) Select a top-up amount or input your own (minimum RM20).</div>
        <div>ii) Request a GoGetter on the app. </div>
        <div>
          iii) The credits will be deducted from your account when the job is
          completed.
        </div>
      </>
    )
  },
  {
    question: 'How do I buy credits?',
    content: (
      <>
        <br />
        <div>i) Select a top-up amount or input your own (minimum RM20).</div>
        <div>ii) Click on “Buy now” and select your payment method.</div>
        <div style={{ marginBottom: '2rem' }}>
          iii) Start posting jobs and use your credits to pay.
        </div>
        <div style={{ fontWeight: 'bold' }}>Types of payment </div>
        <div>a) Online bank transfer</div>
        <div>b) Paypal</div>
      </>
    )
  },
  {
    question: 'Will my credits expire?',
    content: (
      <>
        <p style={{ marginBottom: '1.5rem' }}>
          To ensure your credits are utilised to it’s fullest, simply complete
          one the criterias below and extend your credit expiration date.
        </p>
        <p style={{ marginBottom: '1.5rem' }}>
          i) 12 months from the last date you purchased a credit package or last
          date you received a compensation via credit top up.
        </p>
        <p style={{ marginBottom: '1.5rem' }}>
          ii) 12 months from your last completed job
        </p>
        <p style={{ fontStyle: 'italic', marginBottom: '4rem' }}>
          (whichever of the above two has a longer expiration date)
        </p>
        <p style={{ fontWeight: 'bold' }}>
          Where can I check my credits balance and expiration date?
        </p>
        <p style={{ marginBottom: '2rem' }}>
          Check your credit balance from the GoGet Dashboard.
        </p>
        <img alt="icon" width={437} height={246} src={wallet} />
        <p style={{ fontStyle: 'italic', textAlign: 'center' }}>
          Image shown here is for illustration purposes only
        </p>
      </>
    )
  },
  {
    question: 'Can I use credits to pay for my cost of goods?',
    content: (
      <>
        <br />
        Yes, you can! Just click on Shopping or Food and create a job. Your
        credits can be used to pay the GoGetter for your cost of items and
        delivery fee.
      </>
    )
  }
];
