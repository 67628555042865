import React from 'react';
import styled from 'styled-components/macro';

import { data } from './const/HomeTypeOfJobData';
import TypeOfJob from '../shared/TypeOfJob/index';
import TitleWithIcon from '../../components/TitleWithIcon';

import Typeofjob from '../../assets/img/Typeofjob.png';

const Container = styled.div`
  max-width: 948px;
  margin: 0 auto;
`;

const HomePricingTypeofJob = () => {
  return (
    <Container>
      <TitleWithIcon
        title="Type of job"
        icon={Typeofjob}
        description={
          <div>
            Minimum fee per hour starts from <strong>RM10</strong> depending on
            the service you need.
          </div>
        }
      />
      <TypeOfJob isPrimary data={data} />
    </Container>
  );
};

export default HomePricingTypeofJob;
