import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { Avatar } from '@material-ui/core';

import TitleHeaderText from '../../components/TitleHeaderText';
import FlexAvatarTitleDescription from '../shared/FlexAvatarTitleDescription';
import GoGetPayIcon from './const/assets/GoGetPayIcon.png';

import { data } from './const/PaymentOptionsData';

const Container = styled.div`
  max-width: 707px;
  margin: 0 auto;
  width: 90%;
  position: relative;
  bottom: -35px;
`;
const Paper = styled.div`
  box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
  border-radius: 20px;
  padding: 25px 21px;
  background: #fff;
  &.gogetPay {
    margin-bottom: 24px;
    border: 2px solid #1e8cc4;
    padding: 0px;
    height: 300px;
  }
`;
const HeaderContainer = styled.div`
  background: #1e8cc4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 52px;
`;
const StyledAvatar = styled(Avatar)`
  height: 40px !important;
  width: 40px !important;
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 19.5px;
  line-height: 27px;
  color: #ffffff;
  margin: 8px;
`;
const BodyContainer = styled.div`
  padding: 25px 21px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function PaymentOptions(props) {
  return (
    <Container>
      <TitleHeaderText noMargin text="Safe and secured payment options" />
      <br />
      <Paper className="gogetPay">
        <HeaderContainer>
          <StyledAvatar src={GoGetPayIcon} alt="gg-pay-icon" />
          <Title>GoGet Pay</Title>
        </HeaderContainer>
        <BodyContainer>
          {_.map(data.gogetPay, ggPay => (
            <FlexAvatarTitleDescription
              key={ggPay.id}
              image={ggPay.image}
              title={ggPay.title}
              description={ggPay.description}
            />
          ))}
        </BodyContainer>
      </Paper>
    </Container>
  );
}

export default PaymentOptions;
